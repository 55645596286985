<template>
    <v-row>
        <BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

        <v-col cols="12" class="d-none d-md-block">
            <ToolbarContainer :menu="menu" />
            <v-toolbar elevation="1" height="25" rounded="b">
                <Breadcrumbs />
            </v-toolbar>
        </v-col>

        <v-col cols="12" lg="12">
            <router-view />
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumbs from "../Widgets/Breadcrumbs";
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";

export default {
    name: "VisitasContainer",
    components: { Breadcrumbs, ToolbarContainer, BottomNavigationContainer },
    data: () => ({
        menu: [],
    }),
    computed: {
        ...mapState(["usuario"]),
    },
    methods: {
        montarMenu() {
            let m = [];

            if (this.permissao("controle_visitas_form")) {
                m.push({
                    titulo: "Registro de Visitas",
                    titulo_app: "Controle de Visitas",
                    icon: "mdi-clipboard-file-outline",
                    to: "/controle_visitas",
                    tooltip: "Formulário de registro de visita a obra",
                });
            }

            // if (this.permissao("controle_visitas_resumo_gerente")) {
            //     m.push({
            //         titulo: "Resumo de Visitas",
            //         titulo_app: "Resumo Visitas",
            //         icon: "mdi-file-chart-outline",
            //         to: "/controle_visitas/resumo/filial",
            //         tooltip: "Resumo de visitas da filial e índices de venda.",
            //     });
            // }

          if (this.permissao("controle_visitas_resumo")) {
            m.push({
              titulo: "Dashboard Visitas",
              titulo_app: "Resumo Visitas",
              icon: "mdi-file-chart-outline",
              to: "/controle_visitas/resumo/rede",
              tooltip: "Resumo de visitas da rede e índices de venda.",
            });
          }

            this.menu = m;
        },
    },
    mounted() {
        this.montarMenu();
    },
};
</script>

<style scoped></style>